import React, { useCallback } from "react";
import Router from "next/router";
import { Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { pathNames } from "config/routing";


const ImgAlert = () => {

  const onClick = useCallback(() => Router.push(pathNames.alumnus.href, `${pathNames.alumnus.as("me")}/edit/1/1`), []);

  return (
    <div className="mb-16 w-full">
      <Alert severity="warning" classes={{
        root: "flex flex-row items-center",
        message: "py-0",
      }}>
        <Typography variant="caption" className="mr-8">Még nem töltöttél fel profilképet magadról.</Typography>
        <Button onClick={onClick}
                className="mb-1">
          Kép feltöltése
        </Button>
      </Alert>
    </div>
  );
};

export default React.memo(ImgAlert);

import React from "react"
import { Card, Icon, Typography } from "@material-ui/core";


const OnlyRegisteredWidget = ({ onlyRegistered }) => {

  const [{ members, onlyRegistered: cnt, rate }] = onlyRegistered;

  return (
    <Card className="w-full rounded-8 shadow-none border-solid border-1">

      <div className="p-16 pb-10 flex flex-row flex-wrap md:flex-no-wrap items-center md:items-start md:mt-4">
        <Typography className="mr-8 mt-4" color="textSecondary">
          <Icon className="text-20" color="primary">notifications_active</Icon>
        </Typography>
        <Typography className="h3" color="textSecondary">A regisztrációja óta...</Typography>
      </div>

      <div className="text-center pb-24">
        <div className="flex flex-row items-center justify-center w-full text-40 leading-none">
          <Typography color="primary" className="text-40">{cnt}</Typography>&nbsp;
          <Typography className="text-40" color="textSecondary">/</Typography>&nbsp;
          <Typography className="text-40" color="textSecondary">{members}</Typography>&nbsp;
          <Typography color="primary" className="text-32">({((rate || 0) * 100).toPrecision(1)}%)</Typography>
        </div>
        <Typography className="text-16 pt-12" variant="body1" color="textSecondary">
          alumnus egyszer sem jelentkezett be
        </Typography>
      </div>

    </Card>
  );
};

export default React.memo(OnlyRegisteredWidget);

import React, { useContext, useMemo } from "react";
import Link from "next/link";
import { Icon, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { pathNames } from "config/routing";
import { accent } from "common/helpers";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { getHref } from "components/error/ui/config";
import { ExternalLink } from "components/common";


const HelpInfo = () => {

  const { envVars: { supportEmail, supportEmailUri } } = useContext(SessionContext);

  const href = useMemo(() => getHref("Információkérés", supportEmailUri), [supportEmailUri]);

  return (
    <div className="w-full mb-16">
      <Alert severity="info"
             classes={{
               root: "flex flex-row items-center",
               icon: "hidden sm:flex",
             }}
             icon={<Icon className="text-18">help_outline</Icon>}
      >
        <Typography variant="caption">
          Az aktuálisan megnyitott laphoz tartozó súgót a fejléc jobb szélén található kérdőjel ikonra kattintva
          nyithatod meg. A <Link href={pathNames.faq.href} as={pathNames.faq.as()}>
          <a rel="noopener noreferrer" role="button" className="hover:underline text-blue">
            <Typography variant="caption">gyakran ismételt kérdések lapja</Typography>
          </a></Link> a menüből érhető el. További segítséget {accent(supportEmail)}&nbsp;
        </Typography>
        <ExternalLink href={href} noIcon role="button">
          <Typography variant="caption" className="text-blue hover:underline">{supportEmail}</Typography>
        </ExternalLink>
        <Typography variant="caption">&nbsp;email címen kérhetsz.</Typography>
      </Alert>
    </div>
  );
};

export default React.memo(HelpInfo);

import React, { useContext, useMemo } from "react";
import { useQuery } from "@apollo/react-hooks";
import { dbEnums } from "common/enums";
import { useForbidden } from "client/lib/session";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { revisionStatuses } from "client/store/alumnus/resolvers/getters";
import { GET_ALUMNUS_GROUP } from "client/store/alumnus/queries";
import { FuseAnimate, FusePageSimple } from "components/fuse";
import Layout from "components/layout";
import { helps } from "components/help";
import HelpInfo from "./HelpInfo";
import ImgAlert from "./ImgAlert";
import RevisionAlert from "./RevisionAlert";
import CurrentCards from "./CurrentCards";
import FaqCards from "./FaqCards";
import StatCards from "./StatCards";
import AdminCards from "./AdminCards";
import SMSGatewayBalance from "./SMSGatewayBalance";


const DashboardPage = () => {

  const { personal: { alumnusId } } = useContext(SessionContext);


  const nonAdmin = useForbidden([dbEnums.accountRoles.MANAGER]);


  const { loading, error: alumnusError, data: { members } = {} } = useQuery(GET_ALUMNUS_GROUP, {
    skip: !alumnusId,
    variables: {
      ids: [alumnusId]
    },
  });


  const alumnus = useMemo(() => (members || []).find(({ id }) => id === alumnusId) || {}
    , [alumnusId, members]);


  const { status: { revisionStatus, revisionDate } = {} } = alumnus;


  const error = useMemo(() => !loading && (alumnusError || !alumnus.id)
    , [alumnus, alumnusError, loading]);


  const imgAlert = useMemo(() => !alumnus.img || !alumnus.img.fileId, [alumnus.img]);


  const revisionAlert = useMemo(() => revisionStatus !== revisionStatuses.upToDate, [revisionStatus]);


  return (
    <Layout loading={loading} error={error} help={helps.dashboard}>
      <FusePageSimple
        content={
          <div className="flex flex-col">

            <div className="flex flex-col flex-1 p-24 pt-32 max-w-4xl">
              <FuseAnimate delay={600}>
                <div className="flex flex-col mb-16">
                  {Boolean(nonAdmin) && (
                    <HelpInfo/>
                  )}

                  {!nonAdmin && (
                    <SMSGatewayBalance/>
                  )}

                  {Boolean(imgAlert) && (
                    <ImgAlert/>
                  )}
                  {Boolean(revisionAlert) && (
                    <RevisionAlert revisionStatus={revisionStatus}
                                   revisionDate={revisionDate}
                    />
                  )}
                </div>
              </FuseAnimate>

              <AdminCards/>
              <CurrentCards alumnus={alumnus}/>
              <FaqCards/>
              <StatCards/>
            </div>

          </div>
        }
      />
    </Layout>
  );
};

export default React.memo(DashboardPage);

import React, { useContext } from "react";
import clsx from "clsx";
import Link from "next/link";
import { AppBar, Button, Card, CardContent, Grid, List, makeStyles, Toolbar, Typography } from "@material-ui/core";
import { pathNames } from "config/routing";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { FuseAnimateGroup } from "components/fuse";
import Groupmate from "./Groupmate";


const useStyles = makeStyles(() => ({
  CardContent: {
    "&:last-child": {
      paddingBottom: 4,
    },
  },
}));


const Groupmates = ({ groupmates, groupCnt }) => {

  const { personal: { special } } = useContext(SessionContext);

  const classes = useStyles();

  return Boolean(!special && groupmates && groupmates.length) && (
    <Grid item xs={12} sm={6} lg={4} className="widget w-full flex flex-col">
      <FuseAnimateGroup
        enter={{
          animation: "transition.slideUpBigIn"
        }}
      >
        <Card className="w-full">
          <AppBar position="static" elevation={0}>
            <Toolbar className="pl-16 pr-8 flex items-center">
              <Typography variant="subtitle1" color="inherit" className="flex-1">
                Legutóbb regisztrált osztálytársaid
              </Typography>
              {groupmates.length < groupCnt && (
                <Link href={pathNames.group.href}>
                  <a rel="noopener noreferrer" role="button">
                    <Button color="inherit" size="small" className="text-white">Mindenki</Button>
                  </a>
                </Link>
              )}
            </Toolbar>
          </AppBar>
          <CardContent className={clsx(classes.CardContent, "p-0")}>
            <List>
              {groupmates.map((alumnus) => !alumnus.hiddenAccount && (
                <Groupmate key={alumnus.id}
                           alumnus={alumnus}
                />
              ))}
            </List>
          </CardContent>
        </Card>
      </FuseAnimateGroup>
    </Grid>
  );
};

export default React.memo(Groupmates);

import React, { useMemo } from "react";
import clsx from "clsx";
import { Icon, Tooltip, Typography } from "@material-ui/core";
import { pathNames } from "config/routing";
import { residency_typename } from "client/store/alumnus/resolvers/getters/alumnus";
import { options } from "components/form/common/PrivacyPolicy";
import CurrentCard from "./CurrentCard";


const ResidencyCard = ({ alumnus: { residency: { privacy, cityAndCountry } = {} } }) => {

  const privacyOption = useMemo(() => options.find(({ value }) => value === privacy) || {}, [privacy]);

  return (
    <CurrentCard icon="home"
                 title="Ahol élsz"
                 updateLabel="Máshol élsz?"
                 className="py-24"
                 href={pathNames.alumnus.href}
                 as={`${pathNames.alumnus.as("me")}/edit/0/2/${residency_typename}`}
    >
      <Typography color="textSecondary" className="text-20 py-32">

        <span className="mr-8">{cityAndCountry}</span>

        <Tooltip title={clsx("Adatvédelmi beállítás:", privacyOption.label)}>
          <Icon className="text-16" color={privacyOption.color}>{privacyOption.icon}</Icon>
        </Tooltip>

      </Typography>
    </CurrentCard>
  );
};

export default React.memo(ResidencyCard);

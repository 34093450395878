import React from "react";
import moment from "moment";
import { ListItem, ListItemText } from "@material-ui/core";
import { AlumnusAvatar, AlumnusLink } from "components/alumnus/common";


const Groupmate = ({ alumnus }) => (
  <ListItem>
    <AlumnusAvatar alumnus={alumnus}
                   avatarClassName="mr-16"
                   size={48}
                   rounded
                   link
    />
    <ListItemText
      className="flex-1"
      primary={(
        <div className="truncate">
          <AlumnusLink alumnus={alumnus}/>
        </div>
      )}
      secondary={`Regisztrált: ${moment(alumnus.status.regDate).format("L")}`}
    />
  </ListItem>
);

export default React.memo(Groupmate);

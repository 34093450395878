import React, { useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import { getFaqs } from "config/faqs";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { FuseAnimate } from "components/fuse";
import FaqCard from "./FaqCard";


const highlightedFaqId = 1;

const getRandomFaqId = (envVars, deep = 0) => {
  const faqs = getFaqs(envVars);

  if (faqs.length < 2 || deep > faqs.length) return;

  const id = Math.floor(Math.random() * faqs.length) + 1;
  return id !== highlightedFaqId ? id : getRandomFaqId(deep + 1);
};


const FaqCards = () => {

  const { envVars } = useContext(SessionContext);

  return (
    <div className="mb-40">
      <FuseAnimate delay={600}>
        <Typography className="pb-16 text-18 font-300">
          Jó tudni...
        </Typography>
      </FuseAnimate>

      <Grid container spacing={3}>

        <FaqCard faqId={highlightedFaqId}/>

        <FaqCard faqId={getRandomFaqId(envVars)}/>

      </Grid>
    </div>
  );
};

export default React.memo(FaqCards);

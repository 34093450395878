import React, { useCallback } from "react";
import clsx from "clsx";
import Router from "next/router";
import { Button, Card, Divider, Grid, Icon, Typography } from "@material-ui/core";
import { FuseAnimate } from "components/fuse";


const CurrentCard = ({ icon, title, updateLabel, buttonLabel = "Módosítás", href, as = href, iconClassName, className, lg, children }) => {

  const onClick = useCallback(() => Router.push(href, as), [as, href]);

  return (
    <Grid item xs={12} sm={6} lg={lg} xl={4} className="widget w-full">
      <FuseAnimate delay={600}>
        <Card className="w-full rounded-8 shadow-none border-solid border-1 h-full flex flex-col items-stretch">

          <Grid container alignContent="stretch" className="h-full px-20">

            <Grid item xs={12}>
              <Grid item container alignContent="flex-start" className="h-full">
                <div className="flex flex-row items-center mt-20">
                  <Icon color="secondary" className={clsx("text-22 leading-none mr-12", iconClassName)}>
                    {icon}
                  </Icon>
                  <Typography className="h3" color="textSecondary">{title}</Typography>
                </div>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid item container alignContent="center"
                    className={clsx("h-full", !clsx(className).includes("py-") && "py-32")}>
                {children}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container alignContent="flex-end" className="h-full">
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12} className="py-16 flex flex-row items-center justify-between">
                  {Boolean(updateLabel) && (
                    <Typography color="textSecondary">{updateLabel}</Typography>
                  )}
                  <Button size="small"
                          onClick={onClick}
                  >
                    {buttonLabel}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Card>
      </FuseAnimate>
    </Grid>
  );
};

export default React.memo(CurrentCard);

import React, { useCallback } from "react";
import Router from "next/router";
import clsx from "clsx";
import { Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { pathNames } from "config/routing";
import { revisionStatuses } from "client/store/alumnus/resolvers/getters";


const RevisionAlert = ({ revisionStatus, revisionDate }) => {

  const onClick = useCallback(() => Router.push(pathNames.revision.href), []);

  return (
    <div className="mb-16 w-full">
      <Alert severity={revisionStatus === revisionStatuses.outdated ? "error" : "warning"}
             classes={{
               root: "flex flex-row items-center",
               message: "py-0",
             }}>
        <Typography variant="caption" className="mr-8">
          {clsx("Utoljára mintegy", revisionDate, "erősítetted meg kapcsolattartási adataidat. Kérjük, végezd el az esedékes adatellenőrzést!")}
        </Typography>
        <Button onClick={onClick}
                className="mb-1">
          Ellenőrzöm az adatokat
        </Button>
      </Alert>
    </div>
  );
};

export default React.memo(RevisionAlert);

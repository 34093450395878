import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Grid, Typography } from "@material-ui/core";
import texts from "config/texts";
import { pathNames } from "config/routing";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { GET_GROUP_STATS } from "client/store/alumnus/queries";
import { FuseAnimate } from "components/fuse";
import Groupmates from "./Groupmates";
import GroupCard from "./GroupCard";
import AlumniCard from "./AlumniCard";


const groupmatesCount = 5;


const StatCards = () => {

  const { personal: { alumnusId, grp, special } } = useContext(SessionContext);


  const { loading, error, data: { groupmates, alumni: { stat: { count: alumniCnt } = {} } = {}, group: { stat: { count: groupCnt } = {} } = {} } = {} } = useQuery(GET_GROUP_STATS, {
    skip: !grp,
    variables: {
      group: grp,
      excludedIds: [alumnusId],
      limit: groupmatesCount,
    },
  });


  return Boolean(!loading && !error) && (
    <div className="mb-16">
      <FuseAnimate delay={600}>
        <Typography className="pb-16 text-18 font-300">
          Az Alumni tagsága
        </Typography>
      </FuseAnimate>

      <Grid container spacing={3} alignItems="flex-start">

        <AlumniCard alumniCnt={alumniCnt}
                    title={texts.organization}
                    icon="assignment_ind"
                    buttonLabel="Alumnus kereső"
                    href={pathNames.search.href}
        />

        {!special && (
          <GroupCard groupCnt={groupCnt}
                     title={grp}
                     icon="group"
                     buttonLabel="Ugrás az osztályodhoz"
                     href={pathNames.group.href}
          />
        )}

        {!special && (
          <Groupmates groupmates={groupmates}
                      groupCnt={groupCnt}
          />
        )}

      </Grid>
    </div>
  );
};

export default React.memo(StatCards);

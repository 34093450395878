import React, { useCallback, useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { backendFetch, backendServices } from "client/lib/backendApi";
import { ExternalLink } from "components/common";


const SMSGatewayBalance = () => {

  const [loaded, setLoaded] = useState(false);


  const [points, setPoints] = useState(0);

  const [avgDomesticPrice, setAvgDomesticPrice] = useState(0);

  const [capacity, setCapacity] = useState(0);

  const [severity, setSeverity] = useState("info");


  const getBalance = useCallback(async () => {
    const { error, data: { points, avgDomesticPrice, capacity, warning, critical } = {} } = await backendFetch(backendServices.smsapicom.getBalance);

    if (!error) {
      setPoints(points || 0);
      setAvgDomesticPrice(avgDomesticPrice || 0);
      setCapacity(capacity || 0);
      setSeverity(critical ? "error" : warning ? "warning" : "info");
    }

    setLoaded(!error);
  }, []);


  useEffect(() => {
    if (!loaded) getBalance();
  }, [getBalance, loaded]);


  return Boolean(loaded) && (
    <div className="mb-16 w-full">
      <Alert severity={severity} classes={{
        root: "flex flex-row items-center",
        message: "py-0",
      }}>
        <Typography variant="caption" className="mr-8">
          A kapcsolt <ExternalLink href="https://www.smsapi.com/" noIcon role="button"
                                   className="text-blue hover:underline">smsapi.com</ExternalLink> fiók
          egyenlege {points} pont. Egy SMS díja átlagosan {avgDomesticPrice} pont,
          így {capacity ? `még mintegy ${capacity} db SMS küldhető ki` : "további SMS már nem küldhető ki"}.
        </Typography>
      </Alert>
    </div>
  );
};

export default React.memo(SMSGatewayBalance);

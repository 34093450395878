import React from "react"
import { Card, Icon, Typography } from "@material-ui/core";


const LoginDaysWidget = ({ avgDaysBwLogins }) => {

  const [{ avg }] = avgDaysBwLogins;

  return (
    <Card className="w-full rounded-8 shadow-none border-solid border-1">

      <div className="p-16 pb-10 flex flex-row flex-wrap md:flex-no-wrap items-center md:items-start md:mt-4">
        <Typography className="mr-8 mt-4" color="textSecondary">
          <Icon className="text-20" color="primary">devices</Icon>
        </Typography>
        <Typography className="h3" color="textSecondary">Az alumnusok átlagosan...</Typography>
      </div>

      <div className="text-center pb-24">
        <Typography color="primary" className="text-40">{(avg || 0).toFixed(1)}</Typography>

        <Typography className="text-16 pt-12" variant="body1" color="textSecondary">
          naponta jelentkeznek be
        </Typography>
      </div>

    </Card>
  );
};

export default React.memo(LoginDaysWidget);

import gql from "graphql-tag";


export const GET_ADMIN_STATS = gql`
  query GetAdminStats {
    actionCounts: view_stat_action_counts {
      from: from_date
      interval: interval_str
      logins: cnt_logins
      updates: cnt_updates
      revisions: cnt_revisions
      pswResets: cnt_psw_resets
      pwsChangesWToken: cnt_pws_changes_w_token
      registrations: cnt_registrations
    }
    avgDaysBwLogins: view_stat_avg_days_bw_logins {
      avg: avg_days_bw_logins
    }
    onlyRegistered: view_stat_only_registered {
      members
      onlyRegistered: only_registered
      rate: only_registered_rate
    }
  }
`;

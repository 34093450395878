import React, { useCallback, useContext, useMemo } from "react";
import Router from "next/router";
import { Button, Card, CardActions, CardContent, Grid, Typography } from "@material-ui/core";
import { pathNames } from "config/routing";
import { getFaqs } from "config/faqs";
import { SessionContext } from "client/lib/providers/SessionProvider";
import { FuseAnimate } from "components/fuse";


const FaqCard = ({ faqId }) => {

  const { envVars } = useContext(SessionContext);


  const faq = useMemo(() => getFaqs(envVars).find(({ id }) => id === faqId) || {}, [envVars, faqId]);


  const onClick = useCallback(() => Router.push(pathNames.faq.href, pathNames.faq.as(faqId)), [faqId]);


  return Boolean(faq.id) && (
    <Grid item xs={12} sm={6} className="widget w-full">
      <FuseAnimate delay={600}>
        <Card className="w-full rounded-8 shadow-none border-solid border-1 h-full">
          <Grid container className="h-full">
            <Grid item xs={12}>
              <CardContent>
                <Typography variant="h5" component="h2">{faq.question}</Typography>
                <Typography color="textSecondary" variant="body2" component="p" className="pt-12">
                  {faq.lead}
                </Typography>
              </CardContent>
            </Grid>
            <Grid item xs={12} className="flex flex-col justify-end">
              <CardActions className="px-12 pt-0 pb-12">
                <Button onClick={onClick}
                        size="small"
                >
                  Bővebben
                </Button>
              </CardActions>
            </Grid>
          </Grid>
        </Card>
      </FuseAnimate>
    </Grid>
  );
};

export default React.memo(FaqCard);

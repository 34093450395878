import React, { useMemo } from "react";
import moment from "moment";
import { Grid, Typography } from "@material-ui/core";
import { pathNames } from "config/routing";
import { FuseAnimate } from "components/fuse";
import ResidencyCard from "./ResidencyCard";
import ProfessionalCard from "./ProfessionalCard";


const CurrentCards = ({ alumnus }) => {

  const { year, name, jobs, studies } = alumnus;


  const firstName = useMemo(() => name && (name.nick || name.first), [name]);


  const currentJobs = useMemo(() => (jobs || [])
    .filter(job => job && job.current && (job.company || job.job))
    .map(({ job, companyFull, ...rest }) => ({
      ...rest,
      what: job,
      where: companyFull,
    })), [jobs]);


  const currentStudies = useMemo(() => (studies || [])
    .filter(study => study && study.current && (study.institution || study.degree))
    .map(({ degree, institution, ...rest }) => ({
      ...rest,
      what: degree,
      where: institution,
    })), [studies]);


  const graduateYears = useMemo(() => Math.abs(moment([year]).diff(moment(), "years")), [year]);


  const recentlyGraduated = useMemo(() => graduateYears < 7, [graduateYears]);


  const enabledJobs = useMemo(() => Boolean(currentJobs.length || !recentlyGraduated)
    , [currentJobs.length, recentlyGraduated]);


  const enabledStudies = useMemo(() => Boolean(currentStudies.length || recentlyGraduated)
    , [currentStudies.length, recentlyGraduated]);


  const jobsCount = useMemo(() => (jobs || []).length, [jobs]);


  const studiesCount = useMemo(() => (studies || []).length, [studies]);


  return (
    <div className="mb-40">
      <FuseAnimate delay={600}>
        <Typography className="pb-16 text-18 font-300">
          {firstName}, aktuálisak az alábbi információk?
        </Typography>
      </FuseAnimate>

      <Grid container spacing={3}>
        <ResidencyCard alumnus={alumnus}/>
        {Boolean(enabledJobs) && (
          <ProfessionalCard items={currentJobs}
                            count={jobsCount}
                            updateLabel={jobsCount ? "Máshol (is) dolgozol?" : "Add meg a munkahelyedet!"}
                            buttonLabel={jobsCount ? "Módosítás" : "Munkahely felvitele"}
                            icon="work_outline"
                            title="Aktuális munkaköröd és munkahelyed"
                            noCurrent="A felvett munkahelyek közül egyik sem aktuális"
                            missing="Nem adtál meg munkahelyet"
                            href={pathNames.alumnus.href}
                            as={`${pathNames.alumnus.as("me")}/edit/2/0`}
          />
        )}
        {Boolean(enabledStudies) && (
          <ProfessionalCard items={currentStudies}
                            count={studiesCount}
                            updateLabel={jobsCount ? "Máshol (is) tanulsz?" : "Adj meg egy képzést!"}
                            buttonLabel={jobsCount ? "Módosítás" : "Képzés felvitele"}
                            icon="school"
                            title="Jelenleg itt tanulsz (képzés és intézmény)"
                            noCurrent="A felvett képzések közül egyik sem aktuális"
                            missing="Nem adtál meg képzést"
                            href={pathNames.alumnus.href}
                            as={`${pathNames.alumnus.as("me")}/edit/2/1`}
          />
        )}
      </Grid>
    </div>
  );
};

export default React.memo(CurrentCards);

import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Grid, Typography } from "@material-ui/core";
import { dbEnums } from "common/enums";
import { useForbidden } from "client/lib/session";
import { GET_ADMIN_STATS } from "client/store/stat";
import { FuseAnimate } from "components/fuse";
import { ActionsWidget, OnlyRegisteredWidget, LoginDaysWidget } from "./statWidgets";


const AdminCards = () => {

  const forbidden = useForbidden([dbEnums.accountRoles.MANAGER]);

  const { loading, error, data: { actionCounts, avgDaysBwLogins, onlyRegistered = {} } = {} } = useQuery(GET_ADMIN_STATS, {
    skip: forbidden,
  });

  return !forbidden && !loading && !error && (
    <div className="mb-40">
      <FuseAnimate delay={600}>
        <Typography className="pb-16 text-18 font-300">
          Alumni statisztikák
        </Typography>
      </FuseAnimate>

      <Grid container spacing={3}>
        {Boolean(actionCounts) && (
          <Grid item xs={12} md={6}>
            <ActionsWidget actionCounts={actionCounts}/>
          </Grid>
        )}
        {Boolean(onlyRegistered || avgDaysBwLogins) && (
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <OnlyRegisteredWidget onlyRegistered={onlyRegistered}/>
              </Grid>
              <Grid item xs={12}>
                <LoginDaysWidget avgDaysBwLogins={avgDaysBwLogins}/>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default React.memo(AdminCards);

import React from "react";
import clsx from "clsx";
import { Card, Typography, Grid, List, ListItem, Icon } from "@material-ui/core";
import { MiniChip } from "components/common";


const ActionsWidget = ({ actionCounts }) => (
  <Card className="w-full rounded-8 shadow-none border-solid border-1">

    <div className="p-24 pb-12 flex flex-row items-center">
      <Icon color="secondary" className="text-22 leading-none mr-12">how_to_reg</Icon>
      <Typography className="h3" color="textSecondary">Felhasználói aktivitás</Typography>
    </div>

    <div className="px-24 pb-4">
      <List className="p-0">
        {actionCounts.map(({ interval, registrations, logins, updates, revisions, pswResets, pwsChangesWToken }, index) => (
          <ListItem key={clsx(interval)}
                    className={clsx("border-solid select-text cursor-auto px-0 py-16", index < actionCounts.length - 1 && "border-b-1")}
                    disableRipple
                    disableTouchRipple
                    button
                    dense
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} xl={3}>
                <Typography>{interval}</Typography>
              </Grid>
              <Grid item xs={12} sm={8} xl={9}>
                <Grid container spacing={1}>
                  <Grid item>
                    <MiniChip label={`${registrations} regisztrált`}
                              colorClassName="bg-green"
                              tooltip="Regisztrált alumnusok száma"
                    />
                  </Grid>
                  <Grid item>
                    <MiniChip label={`${logins} bejelentkezett`}
                              colorClassName="bg-blue"
                              tooltip="Bejelentkezettek száma (1 alumnust 1x számolunk)"
                    />
                  </Grid>
                  {Boolean(updates) && (
                    <Grid item>
                      <MiniChip label={`${updates} módosította adatait`}
                                colorClassName="bg-purple"
                                tooltip="Azok száma, akik módosították adataikat (1 alumnust 1x számolunk)"
                      />
                    </Grid>
                  )}
                  {Boolean(revisions) && (
                    <Grid item>
                      <MiniChip label={`${revisions} ellenőrizte adatait`}
                                colorClassName="bg-teal"
                                tooltip="Azok száma, akik ellenőrizték adataikat (1 alumnust 1x számolunk)"
                      />
                    </Grid>
                  )}
                  {Boolean(pswResets || pwsChangesWToken) && (
                    <Grid item>
                      <MiniChip label={`${pswResets} (${pwsChangesWToken}) kért (állított be) új jelszót`}
                                colorClassName="bg-red"
                                tooltip="Azok száma, akik jelszóváltoztatási linket kértek (új jelszót állítottak be) (1 alumnust 1x számolunk)"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    </div>

  </Card>
);

export default React.memo(ActionsWidget);

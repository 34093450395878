import React from "react";
import Link from "next/link"
import { Typography } from "@material-ui/core";
import { pathNames } from "config/routing";
import CurrentCard from "./CurrentCard";


const GroupCard = ({ groupCnt, ...cardProps }) => (
  <CurrentCard {...cardProps} className="py-24" iconClassName="text-blue" lg={4}>
    <div className="text-center w-full justify-center py-32">
      <Typography className="text-72 leading-none" variant="body1">
        <Link href={pathNames.group.href}>
          <a rel="noopener noreferrer" role="button" className="text-blue">{groupCnt}</a>
        </Link>
      </Typography>
      <Typography className="text-16" variant="body1" color="textSecondary">osztálytársad regisztrált</Typography>
    </div>
  </CurrentCard>
);

export default React.memo(GroupCard);

import React from "react";
import clsx from "clsx";
import { Icon, Tooltip, Typography } from "@material-ui/core";
import { options } from "components/form/common/PrivacyPolicy";
import CurrentCard from "./CurrentCard";


const ProfessionalCard = ({ items, count, noCurrent, missing, ...cardProps }) => (
  <CurrentCard {...cardProps} iconClassName={clsx(!items.length && "text-orange")}>
    {items.length
      ? items.map(({ id, what, where, privacy }, index) => {

        const privacyOption = options.find(({ value }) => value === privacy) || {};

        return Boolean(what || where) && (
          <div key={id} className={clsx("w-full", index > 0 && "pt-16")}>
            <Typography color="textSecondary" className="text-20">

              <span className="mr-8">{clsx(what && `${what},`, where)}</span>

              <Tooltip title={clsx("Adatvédelmi beállítás:", privacyOption.label)}>
                <Icon className="text-16" color={privacyOption.color}>{privacyOption.icon}</Icon>
              </Tooltip>

            </Typography>
          </div>
        );
      })
      : (
        <div className={clsx("w-full")}>
          <Typography color="textSecondary" className="text-20">
            <i className="mr-8 text-orange">{count ? noCurrent : missing}</i>
          </Typography>
        </div>
      )
    }
  </CurrentCard>
);

export default React.memo(ProfessionalCard);
